<template>
  <div class="item" :class="{ border_bottom: borderBottom }" @click="goToRoute">
    <div class="d_flex justify_content_between">
      <div class="d_flex">
        <div class="d_flex">
          <div class="title">{{ title }}</div>
          <slot name="subTitle" class="sub_title" />
        </div>
      </div>
      <slot></slot>
      <div class="icon" :class="{ showArrow: !showArrow }">
        <img src="../../../assets/images/public/ic_mine_return.webp" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserInfoItem",
  props: {
    title: {
      type: String,
      default: "标题",
    },
    borderBottom: {
      type: Boolean,
      default: false,
    },
    path: {
      type: String,
      default: "标题",
    },
    showArrow: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    goToRoute() {
      if (!this.showArrow || this.path === "") return;
      this.$emit("goToRoute", this.path);
    },
  },
};
</script>

<style lang="scss" scoped>
.d_flex {
  display: flex;
  align-items: center;
  width: 100%;
}

.justify_content_between {
  justify-content: space-between;
}

.item {
  width: 100%;
  box-sizing: border-box;
  padding: 0.75rem 0rem;
  box-sizing: border-box;
}

.icon {
  display: block;
  width: 1rem;
  transform: translateY(0.1rem);

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

.border_bottom {
  border-bottom: 0.75px solid #d4d4d481;
}

.showArrow {
  opacity: 0;
}
</style>