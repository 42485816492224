<template>
  <Container theme="dark" :padding="false">
    <Header title="个人信息" theme="light" />
    <UserInfoItem v-for="(item, j) in list" :key="item.title" :title="item.title" :borderBottom="j < list.length - 1" :path="item.path" :showArrow="item.showArrow" class="px_2rem bg_light" @goToRoute="goToRoute">
      <div class="avatar" v-if="imageUrl && item.key === 'avatar'" @click="startCamera">
        <img :src="imageUrl" />
      </div>
      <div v-else class="sub_title mr_1rem">
        {{ renderComputedData(item.key) }}
      </div>
    </UserInfoItem>
    <VueImageCropper ref="camera" @postImg="uploadImage" class="VueImageCropper profile_camera" />
  </Container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import VueImageCropper from "@/components/VueImageCropper.vue";
import Container from "../components/Container";
import UserInfoItem from "../components/UserInfoItem.vue";
import avatar from "@/assets/images/public/ic_my_head.webp";
import moment from "moment";
import Header from "@/components/Header.vue";

import { createUUID } from "@/utils/UUID";
export default {
  name: "pointsDetails",
  components: {
    Container,
    UserInfoItem,
    VueImageCropper,
    Header,
  },
  data() {
    return {
      list: [
        {
          title: "头像",
          key: "avatar",
          path: "",
          showArrow: true,
          isPath: false,
        },
        {
          title: "昵称",
          key: "nickName",
          path: "/my/userInfo/nickName",
          showArrow: true,
          isPath: true,
        },
        {
          title: "手机号",
          key: "userMobile",
          path: "",
          showArrow: false,
          isPath: false,
        },
      ],
    };
  },
  computed: {
    ...mapState("users", ["usersData"]),
    imageUrl() {
      return this.usersData && this.usersData.avatar !== "" ? this.usersData.avatar : avatar;
    },
    nickName() {
      return this.usersData ? this.usersData.nickName : "昵称";
    },
    userMobile() {
      return this.usersData ? this.usersData.userMobile : "手机号";
    },
  },
  methods: {
    ...mapActions("orders", ["getLogId", "getUploadImgData"]),
    ...mapActions("users", ["getUsers", "editUserInfo"]),
    ...mapActions("oss", ["getOssUrl", "uploadImg"]),
    renderComputedData(data) {
      return this[data];
    },
    goToRoute(path) {
      this.$router.push({ path: path });
    },
    startCamera() {
      this.$refs.camera.startCamera();
    },
    uploadImage(base64, fileName) {
      this.base64 = base64;
      this.fileName = fileName;

      if (!this.base64 || !this.fileName) return;

      // trader/ossUrl ? name="User_head" + "/" + new SimpleDateFormat("yyyy-MM-dd").format(new Date()) + "/" + UUID.randomUUID() + new File(path).getName();
      let randomUUID = this.generateUUID();

      const path = {
        name: `Appeal_img/${moment(new Date()).format("YYYY-MM-DD")}/${randomUUID}${this.fileName}`,
      };

      //1.使用 this.getUploadImgData GET 取得阿里云图片上传路径(fileUpUrl)
      this.getUploadImgData(path).then((res) => {
        if (res.code === "ok") {
          const url = res.data.fileUpUrl;
          const fileUrl = res.data.fileUrl;
          // 2.透过 this.updateOss PUT 进行图片上传
          this.updateOss(this.base64, url, fileUrl, res.data.contentType);
        }
      });
    },
    generateUUID() {
      // Public Domain/MIT
      var d = new Date().getTime(); //Timestamp
      var d2 = (typeof performance !== "undefined" && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
        var r = Math.random() * 16; //random number between 0 and 16
        if (d > 0) {
          //Use timestamp until depleted
          r = (d + r) % 16 | 0;
          d = Math.floor(d / 16);
        } else {
          //Use microseconds since page-load if supported
          r = (d2 + r) % 16 | 0;
          d2 = Math.floor(d2 / 16);
        }
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      });
    },
    updateOss(base64, url, fileUrl, contentType) {
      // base64转blob
      const blob = this.dataURLtoBlob(base64);
      // blob转arrayBuffer
      const reader = new FileReader();
      reader.readAsArrayBuffer(blob);
      reader.onload = (event) => {
        // arrayBuffer转Buffer
        const buffer = this.toBuffer(event.target.result);

        //上传图片到阿里云
        fetch(url, {
          method: "PUT",
          body: buffer,
          headers: new Headers({
            "Content-Type": contentType,
          }),
        })
          .then(() => {
            this.updateAvatar(fileUrl);
          })
          .catch(() => {
            this.showMessage("图像上传失败");
          });
      };
    },
    updateAvatar(avatar) {
      const data = {
        avatar: avatar,
      };
      this.editUserInfo(data).then((res) => {
        if (res.code === "ok") {
          this.getUsers();
          this.showMessage("图像上传成功");
        } else this.showMessage(res.msg);
      });
    },
    toBuffer(ab) {
      //ArrayBuffer ---> Buffer
      var buf = new Buffer(ab.byteLength);
      var view = new Uint8Array(ab);
      for (var i = 0; i < buf.length; ++i) {
        buf[i] = view[i];
      }
      return buf;
    },
    dataURLtoBlob(dataurl) {
      // base64转blob
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    upload() {
      this.$refs.upload.click();
    },
    uploadChangeHandler(e) {
      if (e.target.files.length <= 0) return;
      let file = e.target.files[0];
      this.updateImage(file);
      // let path = URL.createObjectURL(file)
      // this.appealImages.push(path)
    },
    updateImage(file) {
      let date = moment().format("yyyy-MM-DD");
      let fileName = `Appeal_img/${date}/${createUUID()}${file.name}`;
      let self = this;
      this.getOssUrl({ name: fileName }).then((res) => {
        let data = {
          fileUpUrl: decodeURIComponent(res.data.fileUpUrl),
          contentType: res.data.contentType,
          file,
        };
        self.uploadImg(data).then(() => {});
      });
    },
    showMessage(message) {
      this.$message({
        message: message,
        iconClass: "x",
        center: true,
        duration: 1000,
        customClass: "error_message",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.px_2rem {
  padding: 0.75rem 1.2rem;
}

.avatar {
  display: block;
  width: 3rem;
  transform: translateY(0.1rem);
  margin-right: 0.75rem;
  position: relative;
  overflow: hidden;
  border-radius: 50%;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .VueImageCropper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

.bg_light {
  background: white;
}

.sub_title {
  width: 50%;
  text-align: right;
  color: var(--gray);
}
</style>
